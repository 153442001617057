import {LetrusApi, Utils} from '@letrustech/letrus-api-interfaces';
import * as Sentry from '@sentry/browser';
import {
  applyAndClearHighlights,
  highlightMistakes,
} from 'components/BasicEditor/plugins/LetrusHighlighter';
import {EditorState} from 'draft-js';
import createCompositeDecorator from 'draft-js-plugins-editor/lib/Editor/createCompositeDecorator';
import MultiDecorator from 'draft-js-plugins-editor/lib/Editor/MultiDecorator';
import {fromJS, List, Map} from 'immutable';
import isMobile from 'ismobilejs';
import memoize from 'lodash.memoize';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {action} from 'typesafe-actions';
import {revive} from 'utils/functions/reviveComposition';
import {reviveEditorState} from 'utils/functions/reviveEditorState';
import {
  CompositionDetailExtended,
  LastCompositionReview,
} from 'utils/types/compositions';
import {
  addLikeInCompositionReviewCompetenceService,
  addLikeInCompositionReviewService,
  createCompositionAPIV1Service,
  createCompositionForRewriteTestService,
  createCompositionForTestService,
  fetchAdvancedAvailableReviewCreditsService,
  fetchAdvancedFinishedCompositionsService,
  fetchAdvancedReviewedCompositionsService,
  fetchAdvancedWritingCompositionsService,
  fetchAvailableCreditsService,
  fetchCompositionByIdService,
  fetchCompositionCommentsService,
  fetchCompositionGenreInfoService,
  fetchCompositionGenreService,
  fetchCompositionIletrusScoreService,
  fetchCompositionIletrusTensorFlowScoreService,
  fetchCompositionInstructionsService,
  fetchCompositionJsonStatsAsyncService,
  fetchCompositionsAwaitingReviewService,
  fetchLastCompositionReviewService,
  fetchReviewedCompositionsService,
  fetchUserCompositionsService,
  newSaveCompositionService,
  reopenCompositionService,
  saveCompositionAPIV1Service,
  saveCompositionService,
  saveSpecialCasesService,
  updateCompositionInstructionService,
  writeCompositionService,
  WriteCompositionServiceData,
  writingToRewritingService,
} from '../../services/compositionServices';
import {
  fetchLiveTestDataService,
  fetchTestStudentsInfoService,
  triggerLiveTestService,
} from '../../services/testServices';

// weeird stuff from the editor, if one day in the future, letrus get it's own editor and stop using draftjs, remove it!
const getDecorator = memoize(function getDecorator(highlightName) {
  return new MultiDecorator([
    createCompositeDecorator([
      highlightMistakes({
        highlightName,
      }),
    ]),
  ]);
});

// Action types
export enum CompositionsTypes {
  FETCH_GENRE_REQUEST = '@compositions/FETCH_GENRE_REQUEST',
  FETCH_GENRE_SUCCESS = '@compositions/FETCH_GENRE_SUCCESS',
  FETCH_GENRE_FAILURE = '@compositions/FETCH_GENRE_FAILURE',

  FETCH_GENRE_INFO_REQUEST = '@compositions/FETCH_GENRE_INFO_REQUEST',
  FETCH_GENRE_INFO_SUCCESS = '@compositions/FETCH_GENRE_INFO_SUCCESS',
  FETCH_GENRE_INFO_FAILURE = '@compositions/FETCH_GENRE_INFO_FAILURE',

  FETCH_COMPOSITION_INSTRUCTIONS_REQUEST = '@compositions/FETCH_COMPOSITION_INSTRUCTIONS_REQUEST',
  FETCH_COMPOSITION_INSTRUCTIONS_SUCCESS = '@compositions/FETCH_COMPOSITION_INSTRUCTIONS_SUCCESS',
  FETCH_COMPOSITION_INSTRUCTIONS_FAILURE = '@compositions/FETCH_COMPOSITION_INSTRUCTIONS_FAILURE',

  REOPEN_REQUEST = '@compositions/REOPEN_REQUEST',
  REOPEN_SUCCESS = '@compositions/REOPEN_SUCCESS',
  REOPEN_FAILURE = '@compositions/REOPEN_FAILURE',

  FETCH_BY_ID_REQUEST = '@compositions/FETCH_BY_ID_REQUEST',
  FETCH_BY_ID_SUCCESS = '@compositions/FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILURE = '@compositions/FETCH_BY_ID_FAILURE',

  FETCH_PARENT_OR_CHILD_BY_ID_REQUEST = '@compositions/FETCH_PARENT_OR_CHILD_BY_ID_REQUEST',
  FETCH_PARENT_OR_CHILD_BY_ID_SUCCESS = '@compositions/FETCH_PARENT_OR_CHILD_BY_ID_SUCCESS',
  FETCH_PARENT_OR_CHILD_BY_ID_FAILURE = '@compositions/FETCH_PARENT_OR_CHILD_BY_ID_FAILURE',

  FETCH_USER_COMPOSITIONS_REQUEST = '@compositions/FETCH_USER_COMPOSITIONS_REQUEST',
  FETCH_USER_COMPOSITIONS_SUCCESS = '@compositions/FETCH_USER_COMPOSITIONS_SUCCESS',
  FETCH_USER_COMPOSITIONS_FAILURE = '@compositions/FETCH_USER_COMPOSITIONS_FAILURE',

  FETCH_AVAILABLE_CREDITS_REQUEST = '@compositions/FETCH_AVAILABLE_CREDITS_REQUEST',
  FETCH_AVAILABLE_CREDITS_SUCCESS = '@compositions/FETCH_AVAILABLE_CREDITS_SUCCESS',
  FETCH_AVAILABLE_CREDITS_FAILURE = '@compositions/FETCH_AVAILABLE_CREDITS_FAILURE',

  SAVE_COMPOSITION_REQUEST = '@compositions/SAVE_COMPOSITION_REQUEST',
  SAVE_COMPOSITION_SUCCESS = '@compositions/SAVE_COMPOSITION_SUCCESS',
  SAVE_COMPOSITION_FAILURE = '@compositions/SAVE_COMPOSITION_FAILURE',

  WRITE_COMPOSITION_REQUEST = '@compositions/WRITE_COMPOSITION_REQUEST',
  WRITE_COMPOSITION_SUCCESS = '@compositions/WRITE_COMPOSITION_SUCCESS',
  WRITE_COMPOSITION_FAILURE = '@compositions/WRITE_COMPOSITION_FAILURE',

  CREATE_COMPOSITION_APIV1_REQUEST = '@compositions/CREATE_COMPOSITION_APIV1_REQUEST',
  CREATE_COMPOSITION_APIV1_SUCCESS = '@compositions/CREATE_COMPOSITION_APIV1_SUCCESS',
  CREATE_COMPOSITION_APIV1_FAILURE = '@compositions/CREATE_COMPOSITION_APIV1_FAILURE',

  SAVE_COMPOSITION_APIV1_REQUEST = '@compositions/SAVE_COMPOSITION_APIV1_REQUEST',
  SAVE_COMPOSITION_APIV1_SUCCESS = '@compositions/SAVE_COMPOSITION_APIV1_SUCCESS',
  SAVE_COMPOSITION_APIV1_FAILURE = '@compositions/SAVE_COMPOSITION_APIV1_FAILURE',

  FETCH_REVIEWED_COMPOSITIONS_REQUEST = '@compositions/FETCH_REVIEWED_COMPOSITIONS_REQUEST',
  FETCH_REVIEWED_COMPOSITIONS_SUCCESS = '@compositions/FETCH_REVIEWED_COMPOSITIONS_SUCCESS',
  FETCH_REVIEWED_COMPOSITIONS_FAILURE = '@compositions/FETCH_REVIEWED_COMPOSITIONS_FAILURE',

  FETCH_COMPOSITIONS_AWAITING_REVIEW_REQUEST = '@compositions/FETCH_COMPOSITIONS_AWAITING_REVIEW_REQUEST',
  FETCH_COMPOSITIONS_AWAITING_REVIEW_SUCCESS = '@compositions/FETCH_COMPOSITIONS_AWAITING_REVIEW_SUCCESS',
  FETCH_COMPOSITIONS_AWAITING_REVIEW_FAILURE = '@compositions/FETCH_COMPOSITIONS_AWAITING_REVIEW_FAILURE',

  FETCH_COMPOSITION_JSON_STATS_ASYNC_REQUEST = '@compositions/FETCH_COMPOSITION_JSON_STATS_ASYNC_REQUEST',
  FETCH_COMPOSITION_JSON_STATS_ASYNC_SUCCESS = '@compositions/FETCH_COMPOSITION_JSON_STATS_ASYNC_SUCCESS',
  FETCH_COMPOSITION_JSON_STATS_ASYNC_FAILURE = '@compositions/FETCH_COMPOSITION_JSON_STATS_ASYNC_FAILURE',

  SAVE_SPECIAL_CASES_REQUEST = '@compositions/SAVE_SPECIAL_CASES_REQUEST',
  SAVE_SPECIAL_CASES_SUCCESS = '@compositions/SAVE_SPECIAL_CASES_SUCCESS',
  SAVE_SPECIAL_CASES_FAILURE = '@compositions/SAVE_SPECIAL_CASES_FAILURE',

  WRITING_TO_REWRITING_REQUEST = '@compositions/WRITING_TO_REWRITING_REQUEST',
  WRITING_TO_REWRITING_SUCCESS = '@compositions/WRITING_TO_REWRITING_SUCCESS',
  WRITING_TO_REWRITING_FAILURE = '@compositions/WRITING_TO_REWRITING_FAILURE',

  FETCH_COMPOSITION_COMMENTS_REQUEST = '@compositions/FETCH_COMPOSITION_COMMENTS_REQUEST',
  FETCH_COMPOSITION_COMMENTS_SUCCESS = '@compositions/FETCH_COMPOSITION_COMMENTS_SUCCESS',
  FETCH_COMPOSITION_COMMENTS_FAILURE = '@compositions/FETCH_COMPOSITION_COMMENTS_FAILURE',

  FETCH_LAST_COMPOSITION_REVIEW_REQUEST = '@compositions/FETCH_LAST_COMPOSITION_REVIEW_REQUEST',
  FETCH_LAST_COMPOSITION_REVIEW_SUCCESS = '@compositions/FETCH_LAST_COMPOSITION_REVIEW_SUCCESS',
  FETCH_LAST_COMPOSITION_REVIEW_FAILURE = '@compositions/FETCH_LAST_COMPOSITION_REVIEW_FAILURE',

  UPDATE_COMPOSITION_INSTRUCTION_REQUEST = '@compositions/UPDATE_COMPOSITION_INSTRUCTION_REQUEST',
  UPDATE_COMPOSITION_INSTRUCTION_SUCCESS = '@compositions/UPDATE_COMPOSITION_INSTRUCTION_SUCCESS',
  UPDATE_COMPOSITION_INSTRUCTION_FAILURE = '@compositions/UPDATE_COMPOSITION_INSTRUCTION_FAILURE',

  FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_REQUEST = '@compositions/FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_REQUEST',
  FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_SUCCESS = '@compositions/FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_SUCCESS',
  FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_FAILURE = '@compositions/FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_FAILURE',

  FETCH_ADVANCED_WRITING_COMPOSITIONS_REQUEST = '@compositions/FETCH_ADVANCED_WRITING_COMPOSITIONS_REQUEST',
  FETCH_ADVANCED_WRITING_COMPOSITIONS_SUCCESS = '@compositions/FETCH_ADVANCED_WRITING_COMPOSITIONS_SUCCESS',
  FETCH_ADVANCED_WRITING_COMPOSITIONS_FAILURE = '@compositions/FETCH_ADVANCED_WRITING_COMPOSITIONS_FAILURE',

  FETCH_ADVANCED_FINISHED_COMPOSITIONS_REQUEST = '@compositions/FETCH_ADVANCED_FINISHED_COMPOSITIONS_REQUEST',
  FETCH_ADVANCED_FINISHED_COMPOSITIONS_SUCCESS = '@compositions/FETCH_ADVANCED_FINISHED_COMPOSITIONS_SUCCESS',
  FETCH_ADVANCED_FINISHED_COMPOSITIONS_FAILURE = '@compositions/FETCH_ADVANCED_FINISHED_COMPOSITIONS_FAILURE',

  FETCH_ADVANCED_REVIEWED_COMPOSITIONS_REQUEST = '@compositions/FETCH_ADVANCED_REVIEWED_COMPOSITIONS_REQUEST',
  FETCH_ADVANCED_REVIEWED_COMPOSITIONS_SUCCESS = '@compositions/FETCH_ADVANCED_REVIEWED_COMPOSITIONS_SUCCESS',
  FETCH_ADVANCED_REVIEWED_COMPOSITIONS_FAILURE = '@compositions/FETCH_ADVANCED_REVIEWED_COMPOSITIONS_FAILURE',

  FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_REQUEST = '@compositions/FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_REQUEST',
  FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_SUCCESS = '@compositions/FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_SUCCESS',
  FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_FAILURE = '@compositions/FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_FAILURE',

  FETCH_COMPOSITION_ILETRUS_SCORE_REQUEST = '@compositions/FETCH_COMPOSITION_ILETRUS_SCORE_REQUEST',
  FETCH_COMPOSITION_ILETRUS_SCORE_SUCCESS = '@compositions/FETCH_COMPOSITION_ILETRUS_SCORE_SUCCESS',
  FETCH_COMPOSITION_ILETRUS_SCORE_FAILURE = '@compositions/FETCH_COMPOSITION_ILETRUS_SCORE_FAILURE',

  CREATE_COMPOSITION_FOR_TEST_REQUEST = '@compositions/CREATE_COMPOSITION_FOR_TEST_REQUEST',
  CREATE_COMPOSITION_FOR_TEST_SUCCESS = '@compositions/CREATE_COMPOSITION_FOR_TEST_SUCCESS',
  CREATE_COMPOSITION_FOR_TEST_FAILURE = '@compositions/CREATE_COMPOSITION_FOR_TEST_FAILURE',

  CREATE_COMPOSITION_FOR_REWRITE_TEST_REQUEST = '@compositions/CREATE_COMPOSITION_FOR_REWRITE_TEST_REQUEST',
  CREATE_COMPOSITION_FOR_REWRITE_TEST_SUCCESS = '@compositions/CREATE_COMPOSITION_FOR_REWRITE_TEST_SUCCESS',
  CREATE_COMPOSITION_FOR_REWRITE_TEST_FAILURE = '@compositions/CREATE_COMPOSITION_FOR_REWRITE_TEST_FAILURE',

  ADD_LIKE_IN_COMPOSITION_REVIEW_REQUEST = '@compositions/ADD_LIKE_IN_COMPOSITION_REVIEW_REQUEST',
  ADD_LIKE_IN_COMPOSITION_REVIEW_SUCCESS = '@compositions/ADD_LIKE_IN_COMPOSITION_REVIEW_SUCCESS',
  ADD_LIKE_IN_COMPOSITION_REVIEW_FAILURE = '@compositions/ADD_LIKE_IN_COMPOSITION_REVIEW_FAILURE',

  ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_REQUEST = '@compositions/ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_REQUEST',
  ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_SUCCESS = '@compositions/ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_SUCCESS',
  ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_FAILURE = '@compositions/ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_FAILURE',

  SET_HIGHLIGHT = '@compositions/SET_HIGHLIGHT',

  SET_COMPOSITION_EDITOR_STATE = '@compositions/SET_COMPOSITION_EDITOR_STATE',

  SET_COMPOSITION_LOCAL_STATE = '@compositions/SET_COMPOSITION_LOCAL_STATE',

  CLEAR_PARENT_OR_CHILD = '@compositions/CLEAR_PARENT_OR_CHILD',

  CLEAR_COMPOSITION = '@compositions/CLEAR_COMPOSITION',

  RESET_ERROR_AT = '@compositions/RESET_ERROR_AT',

  RESET_SAVE_COMPOSITION_FAILURE_MESSAGE = '@compositions/RESET_SAVE_COMPOSITION_FAILURE_MESSAGE',
}

// Data types

// State type
export interface CompositionsState extends Map<string, any> {
  readonly data: ImmutableMap<any> | undefined;
  readonly loading: boolean;
  readonly error: number | undefined;
  readonly dataCount: number;

  readonly genres: List<ImmutableMap<LetrusApi.CompositionGenre>>;
  readonly isLoadingGenres: boolean;

  readonly genreInfo: ImmutableMap<LetrusApi.CompositionGenre>;
  readonly isLoadingGenreInfo: boolean;

  readonly compositionInstructions: {
    next?: string;
    results?: List<ImmutableMap<LetrusApi.CompositionInstruction>>;
  };
  readonly isLoadingCompositionInstructions: boolean;

  readonly reopeningComposition: number;
  readonly isLoadingReopeningComposition: boolean;

  readonly isLoadingCredits: boolean;
  readonly lastCompositionsCount: number;
  readonly lastCompositions: any;

  readonly isLoadingReviewedCompositions: boolean;
  readonly reviewedCompositionsError: boolean;
  readonly reviewedCompositions: List<ImmutableMap<LetrusApi.AdminComposition>>;

  readonly isLoadingCompositionsAwaitingReview: boolean;
  readonly compositionsAwaitingReviewError: boolean;
  readonly compositionsAwaitingReview: List<
    ImmutableMap<LetrusApi.AdminComposition>
  >;

  readonly isLoadingCompositionsJsonStatsAsync: boolean;
  readonly compositionsJsonStatsAsyncError: boolean;
  readonly compositionsJsonStatsAsync: ImmutableMap<any>;

  readonly isLoadingCompositionComments: boolean;
  readonly compositionCommentsError: boolean;
  readonly compositionComments: any;

  readonly isLoadingLastCompositionReview: boolean;
  readonly lastCompositionReviewError: boolean;
  readonly lastCompositionReview: ImmutableMap<LastCompositionReview>;

  readonly isLoadingAdvancedAvailableReviewCredits: boolean;
  readonly advancedAvailableReviewCreditsError: boolean;
  readonly advancedAvailableReviewCredits: number;

  readonly isLoadingAdvancedWritingCompositions: boolean;
  readonly advancedWritingCompositionsError: boolean;
  readonly advancedWritingCompositions: List<
    ImmutableMap<LetrusApi.AdminComposition>
  >;

  readonly isLoadingAdvancedFinishedCompositions: boolean;
  readonly advancedFinishedCompositionsError: boolean;
  readonly advancedFinishedCompositions: List<
    ImmutableMap<LetrusApi.AdminComposition>
  >;

  readonly isLoadingAdvancedReviewedCompositions: boolean;
  readonly advancedReviewedCompositionsError: boolean;
  readonly advancedReviewedCompositions: List<
    ImmutableMap<LetrusApi.AdminComposition>
  >;

  readonly isLoadingCompositionIletrusTensorFlowScore: boolean;
  readonly compositionIletrusTensorFlowScoreError: boolean;
  readonly compositionIletrusTensorFlowScore: any;

  readonly isLoadingCompositionById: boolean;
  readonly compositionById: ImmutableMap<LetrusApi.CompositionDetail>;

  readonly isLoadingUserCompositions: boolean;
  readonly userCompositions: any;

  readonly isLoadingNewComposition: boolean;
  readonly newComposition: ImmutableMap<LetrusApi.CompositionDetail>;

  readonly errorAt: null | Date;
  readonly loadingSaveComposition: boolean;
  readonly hasSavedComposition: boolean;

  readonly editorState: any;
  readonly highlight: {highlight: any; highlightName: string};

  readonly loadingWriteToRewrite: boolean;

  readonly parentOrChildCompositionById: ImmutableMap<LetrusApi.CompositionDetail>;
  readonly isLoadingParentOrChildCompositionById: boolean;

  readonly isLoadingAddLikeInCompositionReview: boolean;

  readonly isLoadingAddLikeInCompositionReviewCompetence: boolean;

  readonly saveCompositionFailureMessage: string;
}

// Fetch actions
export const fetchCompositionGenreRequest = (params?: any) =>
  action(CompositionsTypes.FETCH_GENRE_REQUEST, {params});

export const fetchCompositionGenreSuccess = (
  data: LetrusApi.CompositionGenre[],
) => action(CompositionsTypes.FETCH_GENRE_SUCCESS, {data});

export const fetchCompositionGenreFailure = () =>
  action(CompositionsTypes.FETCH_GENRE_FAILURE);

export const fetchCompositionInstructionsRequest = (params?: any) =>
  action(CompositionsTypes.FETCH_COMPOSITION_INSTRUCTIONS_REQUEST, {params});

export const fetchCompositionInstructionsSuccess = (
  data: LetrusApi.CompositionInstruction[],
) => action(CompositionsTypes.FETCH_COMPOSITION_INSTRUCTIONS_SUCCESS, {data});

export const fetchCompositionInstructionsFailure = () =>
  action(CompositionsTypes.FETCH_COMPOSITION_INSTRUCTIONS_FAILURE);

export const fetchCompositionGenreInfoRequest = (
  genreId: number | string,
  params?: Utils.GetParams,
) => action(CompositionsTypes.FETCH_GENRE_INFO_REQUEST, {genreId, params});

export const fetchCompositionGenreInfoSuccess = (
  data: LetrusApi.CompositionGenre,
) => action(CompositionsTypes.FETCH_GENRE_INFO_SUCCESS, {data});

export const fetchCompositionGenreInfoFailure = () =>
  action(CompositionsTypes.FETCH_GENRE_INFO_FAILURE);
export const reopenCompositionRequest = (
  compositionId: number | string,
  params?: Utils.GetParams,
) => action(CompositionsTypes.REOPEN_REQUEST, {compositionId, params});

export const reopenCompositionSuccess = (
  compositionData: LetrusApi.CompositionDetail,
  testData: LetrusApi.Test,
  liveTestData: LetrusApi.Test,
  studentsInfoData: LetrusApi.Test,
) =>
  action(CompositionsTypes.REOPEN_SUCCESS, {
    compositionData,
    testData,
    liveTestData,
    studentsInfoData,
  });

export const reopenCompositionFailure = () =>
  action(CompositionsTypes.REOPEN_FAILURE);

export const fetchCompositionByIdRequest = (compositionId?: number | string) =>
  action(CompositionsTypes.FETCH_BY_ID_REQUEST, {compositionId});

export const fetchCompositionByIdSuccess = (
  data: LetrusApi.CompositionDetail,
) => action(CompositionsTypes.FETCH_BY_ID_SUCCESS, {data});

export const fetchCompositionByIdFailure = () =>
  action(CompositionsTypes.FETCH_BY_ID_FAILURE);

export const fetchParentOrChildCompositionByIdRequest = (
  compositionId: number | string,
) =>
  action(CompositionsTypes.FETCH_PARENT_OR_CHILD_BY_ID_REQUEST, {
    compositionId,
  });

export const fetchParentOrChildCompositionByIdSuccess = (
  data: LetrusApi.CompositionDetail,
) => action(CompositionsTypes.FETCH_PARENT_OR_CHILD_BY_ID_SUCCESS, {data});

export const fetchParentOrChildCompositionByIdFailure = () =>
  action(CompositionsTypes.FETCH_PARENT_OR_CHILD_BY_ID_FAILURE);

export const fetchUserCompositionsRequest = (params?: any) =>
  action(CompositionsTypes.FETCH_USER_COMPOSITIONS_REQUEST, {params});

export const fetchUserCompositionsSuccess = (
  data: LetrusApi.CompositionDetail[],
) => action(CompositionsTypes.FETCH_USER_COMPOSITIONS_SUCCESS, {data});

export const fetchUserCompositionsFailure = () =>
  action(CompositionsTypes.FETCH_USER_COMPOSITIONS_FAILURE);

export const fetchAvailableCreditsRequest = (params?: any) =>
  action(CompositionsTypes.FETCH_AVAILABLE_CREDITS_REQUEST, {params});

export const fetchAvailableCreditsSuccess = (data: any) =>
  action(CompositionsTypes.FETCH_AVAILABLE_CREDITS_SUCCESS, {data});

export const fetchAvailableCreditsFailure = () =>
  action(CompositionsTypes.FETCH_AVAILABLE_CREDITS_FAILURE);

export const saveCompositionRequest = (
  composition: Partial<CompositionDetailExtended>,
) =>
  action(CompositionsTypes.SAVE_COMPOSITION_REQUEST, {
    composition,
  });

export const saveCompositionSuccess = (data: any) =>
  action(CompositionsTypes.SAVE_COMPOSITION_SUCCESS, {data});

export const saveCompositionFailure = (err, composition) => {
  const errorMessage = err.response?.data?.error;
  Sentry.captureException(err, {
    extra: {
      title: 'Erro no envio da redação',
      composition,
    },
  });
  return action(CompositionsTypes.SAVE_COMPOSITION_FAILURE, {
    error: errorMessage,
  });
};

export const writeCompositionRequest = (
  composition: WriteCompositionServiceData,
) =>
  action(CompositionsTypes.WRITE_COMPOSITION_REQUEST, {
    composition,
  });

export const writeCompositionSuccess = (data: any) =>
  action(CompositionsTypes.WRITE_COMPOSITION_SUCCESS, {data});

export const writeCompositionFailure = () =>
  action(CompositionsTypes.WRITE_COMPOSITION_FAILURE);

export const createCompositionAPIV1Request = (composition?: any) =>
  action(CompositionsTypes.CREATE_COMPOSITION_APIV1_REQUEST, {composition});

export const createCompositionAPIV1Success = (
  data: LetrusApi.CompositionDetail,
) => action(CompositionsTypes.CREATE_COMPOSITION_APIV1_SUCCESS, {data});

export const createCompositionAPIV1Failure = () =>
  action(CompositionsTypes.CREATE_COMPOSITION_APIV1_FAILURE);

export const saveCompositionAPIV1Request = (
  composition?: LetrusApi.CompositionDetail,
) => action(CompositionsTypes.SAVE_COMPOSITION_APIV1_REQUEST, {composition});

export const saveCompositionAPIV1Success = (
  data: LetrusApi.CompositionDetail,
) => action(CompositionsTypes.SAVE_COMPOSITION_APIV1_SUCCESS, {data});

export const saveCompositionAPIV1Failure = () =>
  action(CompositionsTypes.SAVE_COMPOSITION_APIV1_FAILURE);

export const fetchReviewedCompositionsRequest = (params?: Utils.GetParams) =>
  action(CompositionsTypes.FETCH_REVIEWED_COMPOSITIONS_REQUEST, {params});

export const fetchReviewedCompositionsSuccess = (
  data: LetrusApi.CompositionDetail[],
) => action(CompositionsTypes.FETCH_REVIEWED_COMPOSITIONS_SUCCESS, {data});

export const fetchReviewedCompositionsFailure = () =>
  action(CompositionsTypes.FETCH_REVIEWED_COMPOSITIONS_FAILURE);

export const fetchCompositionsAwaitingReviewRequest = (
  params?: Utils.GetParams,
) =>
  action(CompositionsTypes.FETCH_COMPOSITIONS_AWAITING_REVIEW_REQUEST, {
    params,
  });

export const fetchCompositionsAwaitingReviewSuccess = (
  data: LetrusApi.CompositionDetail[],
) =>
  action(CompositionsTypes.FETCH_COMPOSITIONS_AWAITING_REVIEW_SUCCESS, {data});

export const fetchCompositionsAwaitingReviewFailure = () =>
  action(CompositionsTypes.FETCH_COMPOSITIONS_AWAITING_REVIEW_FAILURE);

export const fetchCompositionJsonStatsAsyncRequest = (
  composition?: any,
  params?: any,
) =>
  action(CompositionsTypes.FETCH_COMPOSITION_JSON_STATS_ASYNC_REQUEST, {
    composition,
    params,
  });

export const fetchCompositionJsonStatsAsyncSuccess = (data: any) =>
  action(CompositionsTypes.FETCH_COMPOSITION_JSON_STATS_ASYNC_SUCCESS, {data});

export const fetchCompositionJsonStatsAsyncFailure = () =>
  action(CompositionsTypes.FETCH_COMPOSITION_JSON_STATS_ASYNC_FAILURE);

export const saveSpecialCasesRequest = (
  compositionId: number,
  compositionRaw?: string,
) =>
  action(CompositionsTypes.SAVE_SPECIAL_CASES_REQUEST, {
    compositionId,
    compositionRaw,
  });

export const saveSpecialCasesSuccess = (data: any) =>
  action(CompositionsTypes.SAVE_SPECIAL_CASES_SUCCESS, {data});

export const saveSpecialCasesFailure = () =>
  action(CompositionsTypes.SAVE_SPECIAL_CASES_FAILURE);

export const writingToRewritingRequest = (compositionId?: number | string) =>
  action(CompositionsTypes.WRITING_TO_REWRITING_REQUEST, {compositionId});

export const writingToRewritingSuccess = (data: any) =>
  action(CompositionsTypes.WRITING_TO_REWRITING_SUCCESS, {data});

export const writingToRewritingFailure = () =>
  action(CompositionsTypes.WRITING_TO_REWRITING_FAILURE);

export const fetchCompositionCommentsRequest = (
  compositionId?: number | string,
) =>
  action(CompositionsTypes.FETCH_COMPOSITION_COMMENTS_REQUEST, {compositionId});

export const fetchCompositionCommentsSuccess = (data: any) =>
  action(CompositionsTypes.FETCH_COMPOSITION_COMMENTS_SUCCESS, {data});

export const fetchCompositionCommentsFailure = () =>
  action(CompositionsTypes.FETCH_COMPOSITION_COMMENTS_FAILURE);

export const fetchLastCompositionReviewRequest = (
  compositionId?: number | string,
) =>
  action(CompositionsTypes.FETCH_LAST_COMPOSITION_REVIEW_REQUEST, {
    compositionId,
  });

export const fetchLastCompositionReviewSuccess = (data: any) =>
  action(CompositionsTypes.FETCH_LAST_COMPOSITION_REVIEW_SUCCESS, {data});

export const fetchLastCompositionReviewFailure = () =>
  action(CompositionsTypes.FETCH_LAST_COMPOSITION_REVIEW_FAILURE);

export const updateCompositionInstructionRequest = (
  compositionId: number | string,
  instructionId?: Utils.GetParams,
) =>
  action(CompositionsTypes.UPDATE_COMPOSITION_INSTRUCTION_REQUEST, {
    compositionId,
    instructionId,
  });

export const updateCompositionInstructionSuccess = (data: any) =>
  action(CompositionsTypes.UPDATE_COMPOSITION_INSTRUCTION_SUCCESS, {data});

export const updateCompositionInstructionFailure = () =>
  action(CompositionsTypes.UPDATE_COMPOSITION_INSTRUCTION_FAILURE);

export const fetchAdvancedAvailableReviewCreditsRequest = (
  params?: Utils.GetParams,
) =>
  action(CompositionsTypes.FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_REQUEST, {
    params,
  });

export const fetchAdvancedAvailableReviewCreditsSuccess = (data: any) =>
  action(CompositionsTypes.FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_SUCCESS, {
    data,
  });

export const fetchAdvancedAvailableReviewCreditsFailure = () =>
  action(CompositionsTypes.FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_FAILURE);

export const fetchAdvancedWritingCompositionsRequest = (
  params?: Utils.GetParams,
) =>
  action(CompositionsTypes.FETCH_ADVANCED_WRITING_COMPOSITIONS_REQUEST, {
    params,
  });

export const fetchAdvancedWritingCompositionsSuccess = (data: any) =>
  action(CompositionsTypes.FETCH_ADVANCED_WRITING_COMPOSITIONS_SUCCESS, {data});

export const fetchAdvancedWritingCompositionsFailure = () =>
  action(CompositionsTypes.FETCH_ADVANCED_WRITING_COMPOSITIONS_FAILURE);

export const fetchAdvancedFinishedCompositionsRequest = (
  params?: Utils.GetParams,
) =>
  action(CompositionsTypes.FETCH_ADVANCED_FINISHED_COMPOSITIONS_REQUEST, {
    params,
  });

export const fetchAdvancedFinishedCompositionsSuccess = (data: any) =>
  action(CompositionsTypes.FETCH_ADVANCED_FINISHED_COMPOSITIONS_SUCCESS, {
    data,
  });

export const fetchAdvancedFinishedCompositionsFailure = () =>
  action(CompositionsTypes.FETCH_ADVANCED_FINISHED_COMPOSITIONS_FAILURE);

export const fetchAdvancedReviewedCompositionsRequest = (
  params?: Utils.GetParams,
) =>
  action(CompositionsTypes.FETCH_ADVANCED_REVIEWED_COMPOSITIONS_REQUEST, {
    params,
  });

export const fetchAdvancedReviewedCompositionsSuccess = (data: any) =>
  action(CompositionsTypes.FETCH_ADVANCED_REVIEWED_COMPOSITIONS_SUCCESS, {
    data,
  });

export const fetchAdvancedReviewedCompositionsFailure = () =>
  action(CompositionsTypes.FETCH_ADVANCED_REVIEWED_COMPOSITIONS_FAILURE);

export const fetchCompositionIletrusTensorFlowScoreRequest = (
  compositionId?: number | string,
) =>
  action(
    CompositionsTypes.FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_REQUEST,
    {compositionId},
  );

export const fetchCompositionIletrusTensorFlowScoreSuccess = (data: any) =>
  action(
    CompositionsTypes.FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_SUCCESS,
    {data},
  );

export const fetchCompositionIletrusTensorFlowScoreFailure = () =>
  action(CompositionsTypes.FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_FAILURE);

export const fetchCompositionIletrusScoreRequest = (
  compositionId?: number | string,
) =>
  action(CompositionsTypes.FETCH_COMPOSITION_ILETRUS_SCORE_REQUEST, {
    compositionId,
  });

export const fetchCompositionIletrusScoreSuccess = (data: any) =>
  action(CompositionsTypes.FETCH_COMPOSITION_ILETRUS_SCORE_SUCCESS, {data});

export const fetchCompositionIletrusScoreFailure = () =>
  action(CompositionsTypes.FETCH_COMPOSITION_ILETRUS_SCORE_FAILURE);

export const createCompositionForTestRequest = (testId: number) =>
  action(CompositionsTypes.CREATE_COMPOSITION_FOR_TEST_REQUEST, {
    testId,
  });

export const createCompositionForTestSuccess = (data: any) =>
  action(CompositionsTypes.CREATE_COMPOSITION_FOR_TEST_SUCCESS, {data});

export const createCompositionForTestFailure = () =>
  action(CompositionsTypes.CREATE_COMPOSITION_FOR_TEST_FAILURE);

export const createCompositionForRewriteTestRequest = (params?: any) =>
  action(CompositionsTypes.CREATE_COMPOSITION_FOR_REWRITE_TEST_REQUEST, {
    params,
  });

export const createCompositionForRewriteTestSuccess = (data: any) =>
  action(CompositionsTypes.CREATE_COMPOSITION_FOR_REWRITE_TEST_SUCCESS, {data});

export const createCompositionForRewriteTestFailure = () =>
  action(CompositionsTypes.CREATE_COMPOSITION_FOR_REWRITE_TEST_FAILURE);

export const addLikeInCompositionReviewRequest = (
  compositionReviewId: string | number,
  likeInfo: boolean,
) =>
  action(CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_REQUEST, {
    compositionReviewId,
    likeInfo,
  });

export const addLikeInCompositionReviewSuccess = (data: any) =>
  action(CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_SUCCESS, {data});

export const addLikeInCompositionReviewFailure = () =>
  action(CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_FAILURE);

export const addLikeInCompositionReviewCompetenceRequest = (
  compositionReviewId: string | number,
  reviewCompetenceGradeId: string | number,
  likeInfo: boolean,
) =>
  action(CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_REQUEST, {
    compositionReviewId,
    reviewCompetenceGradeId,
    likeInfo,
  });

export const addLikeInCompositionReviewCompetenceSuccess = (data: any) =>
  action(CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_SUCCESS, {
    data,
  });

export const addLikeInCompositionReviewCompetenceFailure = () =>
  action(CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_FAILURE);

export const setHighlight = ({highlight, highlightName}) =>
  action(CompositionsTypes.SET_HIGHLIGHT, {
    highlight,
    highlightName,
  });

export const clearParentOrChildComposition = () =>
  action(CompositionsTypes.CLEAR_PARENT_OR_CHILD);

export const setCompositionEditorState = (editorStateUpdate) =>
  action(CompositionsTypes.SET_COMPOSITION_EDITOR_STATE, {
    editorStateUpdate,
  });

export const setCompositionLocalState = (composition: any) =>
  action(CompositionsTypes.SET_COMPOSITION_LOCAL_STATE, {
    composition,
  });

export const resetCompositionErrorAt = () =>
  action(CompositionsTypes.RESET_ERROR_AT);

export const clearComposition = () =>
  action(CompositionsTypes.CLEAR_COMPOSITION);

export const resetSaveCompositionFailureMessage = () =>
  action(CompositionsTypes.RESET_SAVE_COMPOSITION_FAILURE_MESSAGE);

// Sagas
export function* fetchCompositionById(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionByIdService,
      action.payload.compositionId,
    );
    yield put(fetchCompositionByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionByIdFailure());
  }
}

export function* fetchParentOrChildCompositionById(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionByIdService,
      action.payload.compositionId,
    );
    yield put(fetchParentOrChildCompositionByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchParentOrChildCompositionByIdFailure());
  }
}

export function* fetchUserCompositions(action: AnyAction) {
  try {
    const response = yield call(
      fetchUserCompositionsService,
      action.payload.params,
    );

    yield put(fetchUserCompositionsSuccess(response.data));
  } catch (err) {
    yield put(fetchUserCompositionsFailure());
  }
}

export function* fetchAvailableCredits() {
  try {
    const response = yield call(fetchAvailableCreditsService);
    yield put(fetchAvailableCreditsSuccess(response.data));
  } catch (err) {
    yield put(fetchAvailableCreditsFailure());
  }
}

export function* saveComposition(action: AnyAction) {
  try {
    const response = yield call(
      action.payload.composition.uses_ai_comp
        ? saveCompositionService
        : newSaveCompositionService,
      action.payload.composition,
    );
    yield put(saveCompositionSuccess(response.data));
  } catch (err) {
    yield put(saveCompositionFailure(err, action.payload.composition));
  }
}

export function* writeComposition(action: AnyAction) {
  try {
    const response = yield call(
      writeCompositionService,
      action.payload.composition,
    );
    yield put(writeCompositionSuccess(response.data));
  } catch (err) {
    yield put(writeCompositionFailure());
  }
}

export function* createCompositionAPIV1(action: AnyAction) {
  try {
    const response = yield call(
      createCompositionAPIV1Service,
      action.payload.composition,
    );
    yield put(createCompositionAPIV1Success(response.data));
  } catch (err) {
    yield put(createCompositionAPIV1Failure());
  }
}

export function* saveCompositionAPIV1(action: AnyAction) {
  try {
    const response = yield call(
      saveCompositionAPIV1Service,
      action.payload.composition,
    );
    yield put(saveCompositionAPIV1Success(response.data));
  } catch (err) {
    yield put(saveCompositionAPIV1Failure());
  }
}

export function* fetchReviewedCompositions() {
  try {
    const response = yield call(fetchReviewedCompositionsService);
    yield put(fetchReviewedCompositionsSuccess(response.data));
  } catch (err) {
    yield put(fetchReviewedCompositionsFailure());
  }
}

export function* fetchCompositionsAwaitingReview() {
  try {
    const response = yield call(fetchCompositionsAwaitingReviewService);
    yield put(fetchCompositionsAwaitingReviewSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionsAwaitingReviewFailure());
  }
}

export function* fetchCompositionJsonStatsAsync(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionJsonStatsAsyncService,
      action.payload.composition,
      action.payload.params,
    );

    yield put(fetchCompositionJsonStatsAsyncSuccess(response));
  } catch (err) {
    yield put(fetchCompositionJsonStatsAsyncFailure());
  }
}

export function* saveSpecialCases(action: AnyAction) {
  try {
    const response = yield call(
      saveSpecialCasesService,
      action.payload.compositionId,
      action.payload.compositionRaw,
    );
    yield put(saveSpecialCasesSuccess(response.data));
  } catch (err) {
    yield put(saveSpecialCasesFailure());
  }
}

export function* writingToRewriting(action: AnyAction) {
  try {
    const response = yield call(
      writingToRewritingService,
      action.payload.compositionId,
    );
    yield put(writingToRewritingSuccess(response.data));
  } catch (err) {
    yield put(writingToRewritingFailure());
  }
}

export function* fetchCompositionComments(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionCommentsService,
      action.payload.compositionId,
    );
    yield put(fetchCompositionCommentsSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionCommentsFailure());
  }
}

export function* fetchLastCompositionReview(action: AnyAction) {
  try {
    const response = yield call(
      fetchLastCompositionReviewService,
      action.payload.compositionId,
    );
    yield put(fetchLastCompositionReviewSuccess(response.data));
  } catch (err) {
    yield put(fetchLastCompositionReviewFailure());
  }
}

export function* updateCompositionInstruction(action: AnyAction) {
  try {
    const response = yield call(
      updateCompositionInstructionService,
      action.payload.compositionId,
      action.payload.instructionId,
    );
    yield put(updateCompositionInstructionSuccess(response.data));
  } catch (err) {
    yield put(updateCompositionInstructionFailure());
  }
}

export function* fetchAdvancedAvailableReviewCredits() {
  try {
    const response = yield call(fetchAdvancedAvailableReviewCreditsService);
    yield put(fetchAdvancedAvailableReviewCreditsSuccess(response.data));
  } catch (err) {
    yield put(fetchAdvancedAvailableReviewCreditsFailure());
  }
}

export function* fetchAdvancedWritingCompositions() {
  try {
    const response = yield call(fetchAdvancedWritingCompositionsService);
    yield put(fetchAdvancedWritingCompositionsSuccess(response.data));
  } catch (err) {
    yield put(fetchAdvancedWritingCompositionsFailure());
  }
}

export function* fetchAdvancedFinishedCompositions() {
  try {
    const response = yield call(fetchAdvancedFinishedCompositionsService);
    yield put(fetchAdvancedFinishedCompositionsSuccess(response.data));
  } catch (err) {
    yield put(fetchAdvancedFinishedCompositionsFailure());
  }
}

export function* fetchAdvancedReviewedCompositions() {
  try {
    const response = yield call(fetchAdvancedReviewedCompositionsService);
    yield put(fetchAdvancedReviewedCompositionsSuccess(response.data));
  } catch (err) {
    yield put(fetchAdvancedReviewedCompositionsFailure());
  }
}

export function* fetchCompositionIletrusTensorFlowScore(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionIletrusTensorFlowScoreService,
      action.payload.compositionId,
    );
    yield put(fetchCompositionIletrusTensorFlowScoreSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionIletrusTensorFlowScoreFailure());
  }
}

export function* fetchCompositionIletrusScore(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionIletrusScoreService,
      action.payload.compositionId,
    );
    yield put(fetchCompositionIletrusScoreSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionIletrusScoreFailure());
  }
}

export function* createCompositionForTest(action: AnyAction) {
  try {
    const response = yield call(
      createCompositionForTestService,
      action.payload.testId,
    );
    yield put(createCompositionForTestSuccess(response.data));
  } catch (err) {
    yield put(createCompositionForTestFailure());
  }
}

export function* createCompositionForRewriteTest(action: AnyAction) {
  try {
    const response = yield call(
      createCompositionForRewriteTestService,
      action.payload.params,
    );
    yield put(createCompositionForRewriteTestSuccess(response.data));
  } catch (err) {
    yield put(createCompositionForRewriteTestFailure());
  }
}

export function* addLikeInCompositionReview(action: AnyAction) {
  try {
    const response = yield call(
      addLikeInCompositionReviewService,
      action.payload.compositionReviewId,
      action.payload.likeInfo,
    );
    yield put(addLikeInCompositionReviewSuccess(response.data));
  } catch (err) {
    yield put(addLikeInCompositionReviewFailure());
  }
}

export function* addLikeInCompositionReviewCompetence(action: AnyAction) {
  try {
    const response = yield call(
      addLikeInCompositionReviewCompetenceService,
      action.payload.compositionReviewId,
      action.payload.reviewCompetenceGradeId,
      action.payload.likeInfo,
    );
    yield put(addLikeInCompositionReviewCompetenceSuccess(response.data));
  } catch (err) {
    yield put(addLikeInCompositionReviewCompetenceFailure());
  }
}

export function* fetchCompositionGenre(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionGenreService,
      action.payload.params,
    );
    yield put(fetchCompositionGenreSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionGenreFailure());
  }
}

export function* fetchCompositionInstructions(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionInstructionsService,
      action.payload.params,
    );
    yield put(fetchCompositionInstructionsSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionInstructionsFailure());
  }
}

export function* fetchCompositionGenreInfo(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionGenreInfoService,
      action.payload.genreId,
      action.payload.params,
    );
    yield put(fetchCompositionGenreInfoSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionGenreInfoFailure());
  }
}

export function* reopenComposition(action: AnyAction) {
  try {
    const compositionResponse = yield call(
      reopenCompositionService,
      action.payload.compositionId,
      action.payload.params,
    );
    const testDataResponse = yield call(
      fetchLiveTestDataService,
      action.payload.compositionId,
    );
    const liveTestResponse = yield call(
      triggerLiveTestService,
      action.payload.compositionId,
    );
    const studentsInfoResponse = yield call(
      fetchTestStudentsInfoService,
      action.payload.compositionId,
    );

    yield put(
      reopenCompositionSuccess(
        compositionResponse.data,
        testDataResponse.data,
        liveTestResponse.data,
        studentsInfoResponse.data,
      ),
    );
  } catch (err) {
    yield put(reopenCompositionFailure());
  }
}

// Selectors
const compositionsSelector = (state) => state.get('compositions');

export const getCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions,
);

export const getLastCompositionReview = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('lastCompositionReview'),
);

export const getIsLoadingLastCompositionReview = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingLastCompositionReview'),
);

export const getCompositionGenres = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('genres'),
);

export const getCompositionGenreInfo = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('genreInfo'),
);

export const getCompositionInstructions = createSelector(
  compositionsSelector,
  (compositions): List<ImmutableMap<LetrusApi.CompositionInstruction>> =>
    compositions.getIn(['compositionInstructions', 'results']),
);

export const getCompositionInstructionsNext = createSelector(
  compositionsSelector,
  (compositions): string =>
    compositions.getIn(['compositionInstructions', 'next']),
);

export const getCompositionGenreCompetences = createSelector(
  compositionsSelector,
  (compositions) => compositions.getIn(['genreInfo', 'competences']),
);

export const getCompositionGenreShownStats = createSelector(
  compositionsSelector,
  (compositions) => compositions.getIn(['genreInfo', 'shown_stats']),
);

export const getIletrusScore = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('iletrus_tensor_flow_score'),
);

export const isLoadingGenreInfo = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingGenreInfo'),
);

export const isLoadingCompositionInstructions = createSelector(
  compositionsSelector,
  (compositions): boolean =>
    compositions.get('isLoadingCompositionInstructions'),
);

export const getReopeningComposition = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('reopeningComposition'),
);

export const getCompositionJsonStatsAsync = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('compositionsJsonStatsAsync'),
);

export const getCompositionJsonStatsAsyncMetadata = createSelector(
  compositionsSelector,
  (compositions) =>
    compositions.getIn(['compositionsJsonStatsAsync', 'jsonMetadata']),
);

export const getCompositionById = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('compositionById'),
);

export const getCompositionReview = createSelector(
  compositionsSelector,
  (compositions) => {
    const compositionReviews = compositions.getIn([
      'compositionById',
      'reviews',
    ]);
    if (compositionReviews) {
      return compositionReviews[0];
    }

    return undefined;
  },
);

export const getCompositionIsFundII = createSelector(
  compositionsSelector,
  (compositions) =>
    compositions.getIn(['compositionById', 'genre', 'review_grid_name']) ===
    'Grade Única',
);

export const getCompositionSchoolGradeName = createSelector(
  compositionsSelector,
  (compositions) =>
    compositions.getIn(['compositionById', 'test', 'school_group_grade_name']),
);

export const getCompositionGenre = createSelector(
  compositionsSelector,
  (compositions) => compositions.getIn(['compositionById', 'genre']),
);

export const getIsLoadingCompositionById = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingCompositionById'),
);

export const isLoadingCompositionsJsonStatsAsync = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingCompositionsJsonStatsAsync'),
);

export const getCompositionIletrusTensorFlowScore = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('compositionIletrusTensorFlowScore'),
);

export const getUserCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('userCompositions'),
);

export const isLoadingUserCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingUserCompositions'),
);

export const getNewComposition = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('newComposition'),
);

export const getParentOrChildCompositionById = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('parentOrChildCompositionById'),
);

export const isLoadingParentOrChildCompositionById = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingParentOrChildCompositionById'),
);

export const isLoadingNewComposition = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingNewComposition'),
);

export const getReviewedCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('reviewedCompositions'),
);

export const isLoadingReviewedCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingReviewedCompositions'),
);

export const getCompositionsAwaitingReview = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('compositionsAwaitingReview'),
);

export const isLoadingCompositionsAwaitingReview = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingCompositionsAwaitingReview'),
);

export const getAdvancedWritingCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('advancedWritingCompositions'),
);

export const isLoadingAdvancedWritingCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingAdvancedWritingCompositions'),
);

export const getAdvancedReviewedCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('advancedReviewedCompositions'),
);

export const isLoadingAdvancedReviewedCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingAdvancedReviewedCompositions'),
);

export const getAdvancedFinishedCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('advancedFinishedCompositions'),
);

export const isLoadingAdvancedFinishedCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('isLoadingAdvancedFinishedCompositions'),
);

export const getAdvancedAvailableReviewCredits = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('advancedAvailableReviewCredits'),
);

export const getErrorAt = createSelector(compositionsSelector, (compositions) =>
  compositions.get('errorAt'),
);

export const getIsLoadingSaveComposition = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('loadingSaveComposition'),
);

export const hasSavedComposition = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('hasSavedComposition'),
);

export const getEditorState = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('editorState'),
);

export const isLoadingWriteToRewrite = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('loadingWriteToRewrite'),
);

export const getError = createSelector(compositionsSelector, (compositions) =>
  compositions.get('error'),
);

export const getJsonStatsAsyncFetchError = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('compositionsJsonStatsAsyncError'),
);

export const getIletrusLoadingState = createSelector(
  compositionsSelector,
  (compositions) =>
    compositions.get('isLoadingCompositionIletrusTensorFlowScore'),
);

export const getCompositionFetchError = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('compositionFetchError'),
);

export const getSaveCompositionFailureMessage = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('saveCompositionFailureMessage'),
);

// Initial state
export const INITIAL_STATE: CompositionsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
  genres: fromJS([]),
  isLoadingGenres: false,
  genreInfo: fromJS({}),
  isLoadingGenreInfo: false,
  reopeningComposition: null,
  isLoadingReopeningComposition: false,
  isLoadingCompositionInstructions: false,
  compositionInstructions: {
    next: null,
    results: fromJS({}),
  },
  isLoadingCredits: false,
  lastCompositionsCount: 0,
  lastCompositions: fromJS([]),
  isLoadingReviewedCompositions: false,
  reviewedCompositionsError: false,
  reviewedCompositions: fromJS([]),
  isLoadingCompositionsAwaitingReview: false,
  compositionsAwaitingReviewError: false,
  compositionsAwaitingReview: fromJS([]),
  isLoadingCompositionsJsonStatsAsync: false,
  compositionsJsonStatsAsyncError: false,
  compositionsJsonStatsAsync: fromJS({}),
  isLoadingCompositionComments: false,
  compositionCommentsError: false,
  compositionComments: fromJS([]),
  isLoadingLastCompositionReview: false,
  lastCompositionReviewError: false,
  lastCompositionReview: fromJS({}),
  isLoadingAdvancedAvailableReviewCredits: false,
  advancedAvailableReviewCreditsError: false,
  advancedAvailableReviewCredits: 0,
  isLoadingAdvancedWritingCompositions: false,
  advancedWritingCompositionsError: false,
  advancedWritingCompositions: fromJS([]),
  isLoadingAdvancedFinishedCompositions: false,
  advancedFinishedCompositionsError: false,
  advancedFinishedCompositions: fromJS([]),
  isLoadingAdvancedReviewedCompositions: false,
  advancedReviewedCompositionsError: false,
  advancedReviewedCompositions: fromJS([]),
  isLoadingCompositionIletrusTensorFlowScore: false,
  compositionIletrusTensorFlowScoreError: false,
  compositionIletrusTensorFlowScore: 0,
  isLoadingCompositionById: false,
  compositionById: fromJS({}),
  isLoadingUserCompositions: false,
  userCompositions: fromJS({}),
  isLoadingNewComposition: false,
  newComposition: fromJS({}),
  errorAt: null,
  loadingSaveComposition: false,
  hasSavedComposition: false,
  editorState: null,
  loadingWriteToRewrite: false,
  highlight: null,
  parentOrChildCompositionById: fromJS([]),
  isLoadingParentOrChildCompositionById: false,
  isLoadingAddLikeInCompositionReview: false,
  isLoadingAddLikeInCompositionReviewCompetence: false,
  compositionFetchError: false,
  saveCompositionFailureMessage: '',
});

// Reducer
export const reducer: Reducer<CompositionsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case CompositionsTypes.FETCH_GENRE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingGenres', true).set('error', false),
      );

    case CompositionsTypes.FETCH_GENRE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('genres', fromJS(action.payload.data.results))
          .set('dataCount', fromJS(action.payload.data.count))
          .set('isLoadingGenres', false)
          .set('error', false),
      );

    case CompositionsTypes.FETCH_GENRE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingGenres', false).set('error', true),
      );

    case CompositionsTypes.FETCH_COMPOSITION_INSTRUCTIONS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionInstructions', true)
          .set('error', false),
      );

    case CompositionsTypes.FETCH_COMPOSITION_INSTRUCTIONS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .setIn(
            ['compositionInstructions', 'results'],
            fromJS(action.payload.data.results),
          )
          .setIn(
            ['compositionInstructions', 'next'],
            fromJS(action.payload.data.next),
          )
          .set('isLoadingCompositionInstructions', false)
          .set('error', false),
      );

    case CompositionsTypes.FETCH_COMPOSITION_INSTRUCTIONS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionInstructions', false)
          .set('error', true),
      );

    case CompositionsTypes.FETCH_GENRE_INFO_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingGenreInfo', true).set('error', false),
      );

    case CompositionsTypes.FETCH_GENRE_INFO_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('genreInfo', fromJS(action.payload.data))
          .set('isLoadingGenreInfo', false)
          .set('error', false),
      );

    case CompositionsTypes.FETCH_GENRE_INFO_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingGenreInfo', false).set('error', true),
      );

    case CompositionsTypes.REOPEN_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingReopeningComposition', true)
          .set('error', false)
          .set('reopeningComposition', action.payload.compositionId),
      );

    case CompositionsTypes.REOPEN_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('reopeningComposition', null)
          .set('isLoadingReopeningComposition', false)
          .set('error', false),
      );

    case CompositionsTypes.REOPEN_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingReopeningComposition', false)
          .set('error', true)
          .set('reopeningComposition', null),
      );

    case CompositionsTypes.FETCH_BY_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionById', true)
          .set('error', false)
          .set('errorAt', null)
          .set('compositionFetchError', false),
      );

    // TODO: Na migração do EditorPage
    case CompositionsTypes.FETCH_BY_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionById', false)
          .set('error', false)
          .set('errorAt', null)
          .set('compositionById', fromJS(action.payload.data))
          .set('editorState', reviveEditorState(action.payload.data))
          .set('compositionFetchError', false),
      );

    case CompositionsTypes.FETCH_BY_ID_FAILURE:
      return state.withMutations((prevState) => {
        return prevState
          .set('compositionFetchError', true)
          .set('isLoadingCompositionById', false)
          .set('error', true);
      });

    case CompositionsTypes.FETCH_PARENT_OR_CHILD_BY_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingParentOrChildCompositionById', true)
          .set('error', false),
      );

    case CompositionsTypes.FETCH_PARENT_OR_CHILD_BY_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingParentOrChildCompositionById', false)
          .set('error', false)
          .set('parentOrChildCompositionById', fromJS(action.payload.data)),
      );

    case CompositionsTypes.FETCH_PARENT_OR_CHILD_BY_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingParentOrChildCompositionById', false)
          .set('error', true),
      );

    case CompositionsTypes.FETCH_USER_COMPOSITIONS_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUserCompositions', true).set('error', false),
      );

    // TODO: Na migração do EditorPage
    case CompositionsTypes.FETCH_USER_COMPOSITIONS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingUserCompositions', false)
          .set('error', false)
          .set('userCompositions', fromJS(action.payload.data)),
      );

    case CompositionsTypes.FETCH_USER_COMPOSITIONS_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUserCompositions', false).set('error', true),
      );

    case CompositionsTypes.FETCH_AVAILABLE_CREDITS_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingCredits', true).set('error', null),
      );

    case CompositionsTypes.FETCH_AVAILABLE_CREDITS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCredits', false)
          .set('lastCompositionsCount', action.payload.count)
          .set(
            'lastCompositions',
            action.payload.results.map((composition) => revive(composition)),
          )
          .set('error', false),
      );

    case CompositionsTypes.FETCH_AVAILABLE_CREDITS_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingCredits', false).set('error', true),
      );

    case CompositionsTypes.SAVE_COMPOSITION_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('loadingSaveComposition', true)
          .set('hasSavedComposition', false)
          .set('errorAt', null),
      );

    case CompositionsTypes.SAVE_COMPOSITION_SUCCESS: {
      if (state.get('compositionById')?.size) {
        return state.withMutations((prevState) =>
          prevState
            .set('loadingSaveComposition', false)
            .set('hasSavedComposition', true)
            .set('errorAt', null)
            .set(
              'compositionById',
              fromJS({
                ...state.get('compositionById').toJS(),
                ...action.payload.data,
              }),
            ),
        );
      }

      return state.withMutations((prevState) =>
        prevState
          .set('loadingSaveComposition', false)
          .set('hasSavedComposition', false)
          .set('compositionById', fromJS(action.payload.data)),
      );
    }

    case CompositionsTypes.SAVE_COMPOSITION_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loadingSaveComposition', false)
          .set('errorAt', new Date())
          .set('saveCompositionFailureMessage', action.payload.error),
      );

    case CompositionsTypes.WRITE_COMPOSITION_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('loadingSaveComposition', true)
          .set('hasSavedComposition', false)
          .set('errorAt', null),
      );

    case CompositionsTypes.WRITE_COMPOSITION_SUCCESS: {
      if (state.get('compositionById')?.size) {
        return state.withMutations((prevState) =>
          prevState
            .set('loadingSaveComposition', false)
            .set('hasSavedComposition', true)
            .set('errorAt', null)
            .set(
              'compositionById',
              fromJS({
                ...state.get('compositionById').toJS(),
                ...{
                  id: action.payload.data.id,
                  title: action.payload.data.title,
                  composition_raw: action.payload.data.composition_raw,
                  finished: action.payload.data.finished,
                },
              }),
            ),
        );
      }

      return state.withMutations((prevState) =>
        prevState
          .set('loadingSaveComposition', false)
          .set('hasSavedComposition', false)
          .set('compositionById', fromJS(action.payload.data)),
      );
    }

    case CompositionsTypes.WRITE_COMPOSITION_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loadingSaveComposition', false)
          .set('errorAt', new Date()),
      );

    case CompositionsTypes.CREATE_COMPOSITION_APIV1_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingNewComposition', true).set('error', false),
      );

    case CompositionsTypes.CREATE_COMPOSITION_APIV1_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingNewComposition', false)
          .set('newComposition', fromJS(action.payload.data))
          .set('error', false),
      );

    case CompositionsTypes.CREATE_COMPOSITION_APIV1_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingNewComposition', false).set('error', true),
      );

    case CompositionsTypes.SAVE_COMPOSITION_APIV1_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    // TODO: Na migração do EditorPage
    case CompositionsTypes.SAVE_COMPOSITION_APIV1_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.SAVE_COMPOSITION_APIV1_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.FETCH_REVIEWED_COMPOSITIONS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingReviewedCompositions', true)
          .set('reviewedCompositionsError', false),
      );

    case CompositionsTypes.FETCH_REVIEWED_COMPOSITIONS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingReviewedCompositions', false)
          .set('reviewedCompositions', fromJS(action.payload.data))
          .set('reviewedCompositionsError', false),
      );

    case CompositionsTypes.FETCH_REVIEWED_COMPOSITIONS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingReviewedCompositions', false)
          .set('reviewedCompositionsError', true),
      );

    case CompositionsTypes.FETCH_COMPOSITIONS_AWAITING_REVIEW_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionsAwaitingReview', true)
          .set('compositionsAwaitingReviewError', false),
      );

    case CompositionsTypes.FETCH_COMPOSITIONS_AWAITING_REVIEW_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionsAwaitingReview', false)
          .set('compositionsAwaitingReview', fromJS(action.payload.data))
          .set('compositionsAwaitingReviewError', false),
      );

    case CompositionsTypes.FETCH_COMPOSITIONS_AWAITING_REVIEW_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionsAwaitingReview', null)
          .set('compositionsAwaitingReviewError', null),
      );

    case CompositionsTypes.FETCH_COMPOSITION_JSON_STATS_ASYNC_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionsJsonStatsAsync', true)
          .set('compositionsJsonStatsAsyncError', false),
      );

    case CompositionsTypes.FETCH_COMPOSITION_JSON_STATS_ASYNC_SUCCESS:
      return state.withMutations((prevState) => {
        // when data has message attribute is an Error payload
        if (action.payload.data?.message) {
          return prevState
            .set('isLoadingCompositionsJsonStatsAsync', false)
            .set('compositionsJsonStatsAsyncError', true);
        }
        return prevState
          .set('isLoadingCompositionsJsonStatsAsync', false)
          .set('compositionsJsonStatsAsync', fromJS(action.payload.data))
          .set('compositionsJsonStatsAsyncError', false);
      });

    case CompositionsTypes.FETCH_COMPOSITION_JSON_STATS_ASYNC_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionsJsonStatsAsync', false)
          .set('compositionsJsonStatsAsyncError', true),
      );

    case CompositionsTypes.SAVE_SPECIAL_CASES_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    // TODO: Na migração do EditorPage
    case CompositionsTypes.SAVE_SPECIAL_CASES_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.SAVE_SPECIAL_CASES_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.WRITING_TO_REWRITING_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loadingWriteToRewrite', true),
      );

    case CompositionsTypes.WRITING_TO_REWRITING_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loadingWriteToRewrite', false)
          .set('compositionById', fromJS(action.payload.data)),
      );

    case CompositionsTypes.WRITING_TO_REWRITING_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loadingWriteToRewrite', false).set('errot', true),
      );

    case CompositionsTypes.FETCH_COMPOSITION_COMMENTS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionComments', true)
          .set('compositionCommentsError', false),
      );

    case CompositionsTypes.FETCH_COMPOSITION_COMMENTS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionComments', false)
          .set('compositionComments', fromJS(action.payload))
          .set('compositionCommentsError', false),
      );

    case CompositionsTypes.FETCH_COMPOSITION_COMMENTS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionComments', false)
          .set('compositionCommentsError', true),
      );

    case CompositionsTypes.FETCH_LAST_COMPOSITION_REVIEW_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLastCompositionReview', true)
          .set('lastCompositionReviewError', false),
      );

    case CompositionsTypes.FETCH_LAST_COMPOSITION_REVIEW_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLastCompositionReview', false)
          .set('lastCompositionReview', fromJS(action.payload.data))
          .set('lastCompositionReviewError', false),
      );

    case CompositionsTypes.FETCH_LAST_COMPOSITION_REVIEW_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLastCompositionReview', false)
          .set('lastCompositionReviewError', true),
      );

    case CompositionsTypes.UPDATE_COMPOSITION_INSTRUCTION_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.UPDATE_COMPOSITION_INSTRUCTION_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.UPDATE_COMPOSITION_INSTRUCTION_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedAvailableReviewCredits', true)
          .set('advancedAvailableReviewCreditsError', false),
      );

    case CompositionsTypes.FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedAvailableReviewCredits', false)
          .set(
            'advancedAvailableReviewCredits',
            fromJS(action.payload.data.available_review_credits),
          )
          .set('advancedAvailableReviewCreditsError', null),
      );

    case CompositionsTypes.FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedAvailableReviewCredits', false)
          .set('advancedAvailableReviewCreditsError', true),
      );

    case CompositionsTypes.FETCH_ADVANCED_WRITING_COMPOSITIONS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedWritingCompositions', true)
          .set('advancedWritingCompositionsError', false),
      );

    case CompositionsTypes.FETCH_ADVANCED_WRITING_COMPOSITIONS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedWritingCompositions', false)
          .set('advancedWritingCompositions', fromJS(action.payload.data))
          .set('advancedWritingCompositionsError', false),
      );

    case CompositionsTypes.FETCH_ADVANCED_WRITING_COMPOSITIONS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedWritingCompositions', false)
          .set('advancedWritingCompositionsError', true),
      );

    case CompositionsTypes.FETCH_ADVANCED_FINISHED_COMPOSITIONS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedFinishedCompositions', true)
          .set('advancedFinishedCompositionsError', false),
      );

    case CompositionsTypes.FETCH_ADVANCED_FINISHED_COMPOSITIONS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedFinishedCompositions', false)
          .set('advancedFinishedCompositions', fromJS(action.payload.data))
          .set('advancedFinishedCompositionsError', false),
      );

    case CompositionsTypes.FETCH_ADVANCED_FINISHED_COMPOSITIONS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedFinishedCompositions', false)
          .set('advancedFinishedCompositionsError', true),
      );

    case CompositionsTypes.FETCH_ADVANCED_REVIEWED_COMPOSITIONS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedReviewedCompositions', true)
          .set('advancedReviewedCompositionsError', false),
      );

    case CompositionsTypes.FETCH_ADVANCED_REVIEWED_COMPOSITIONS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedReviewedCompositions', false)
          .set('advancedReviewedCompositions', fromJS(action.payload.data))
          .set('advancedReviewedCompositionsError', false),
      );

    case CompositionsTypes.FETCH_ADVANCED_REVIEWED_COMPOSITIONS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAdvancedReviewedCompositions', false)
          .set('advancedReviewedCompositionsError', true),
      );

    case CompositionsTypes.FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionIletrusTensorFlowScore', true)
          .set('compositionIletrusTensorFlowScoreError', false),
      );

    case CompositionsTypes.FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionIletrusTensorFlowScore', false)
          .set(
            'compositionIletrusTensorFlowScore',
            fromJS(action.payload.data.iletrus_tensor_flow_score),
          )
          .set('compositionIletrusTensorFlowScoreError', false),
      );

    case CompositionsTypes.FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCompositionIletrusTensorFlowScore', false)
          .set('compositionIletrusTensorFlowScoreError', true),
      );

    case CompositionsTypes.FETCH_COMPOSITION_ILETRUS_SCORE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.FETCH_COMPOSITION_ILETRUS_SCORE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.FETCH_COMPOSITION_ILETRUS_SCORE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('', null).set('', null).set('', null),
      );

    case CompositionsTypes.CREATE_COMPOSITION_FOR_TEST_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingNewComposition', true).set('error', false),
      );

    // TODO: Na migração do EditorPage
    case CompositionsTypes.CREATE_COMPOSITION_FOR_TEST_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingNewComposition', false)
          .set('newComposition', fromJS(action.payload.data))
          .set('error', false),
      );

    case CompositionsTypes.CREATE_COMPOSITION_FOR_TEST_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingNewComposition', false).set('error', true),
      );

    case CompositionsTypes.CREATE_COMPOSITION_FOR_REWRITE_TEST_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingNewComposition', true).set('error', false),
      );

    case CompositionsTypes.CREATE_COMPOSITION_FOR_REWRITE_TEST_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingNewComposition', false)
          .set('newComposition', fromJS(action.payload.data))
          .set('error', false),
      );

    case CompositionsTypes.CREATE_COMPOSITION_FOR_REWRITE_TEST_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingNewComposition', false).set('error', true),
      );

    case CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingAddLikeInCompositionReview', true),
      );

    case CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingAddLikeInCompositionReview', false),
      );

    case CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingAddLikeInCompositionReview', false),
      );

    case CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingAddLikeInCompositionReviewCompetence', true),
      );

    case CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingAddLikeInCompositionReviewCompetence', false),
      );

    case CompositionsTypes.ADD_LIKE_IN_COMPOSITION_REVIEW_COMPETENCE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingAddLikeInCompositionReviewCompetence', false),
      );

    case CompositionsTypes.SET_HIGHLIGHT: {
      const highlight = {
        highlight: action.payload.highlight,
        highlightName: action.payload.highlightName,
      };

      const oldHighlight = state.get('highlight');
      const editorState = state.get('editorState');

      if (!editorState.getCurrentContent) {
        return state.withMutations((prevState) =>
          prevState.set('highlight', highlight),
        );
      }

      if ((isMobile as any).any) {
        return state.withMutations((prevState) =>
          prevState.set('highlight', highlight),
        );
      }

      return state
        .withMutations((prevState) => prevState.set('highlight', highlight))
        .set(
          'editorState',
          EditorState.set(
            applyAndClearHighlights({
              editorState,
              highlightName: highlight.highlightName,
              newHighlight: highlight.highlight,
              oldHighlight: !oldHighlight ? null : oldHighlight.highlight,
              oldHighlightName: !oldHighlight
                ? null
                : oldHighlight.highlightName,
            }),
            {
              decorator: getDecorator(highlight.highlightName),
            },
          ),
        );
    }

    case CompositionsTypes.SET_COMPOSITION_EDITOR_STATE:
      return state.withMutations((prevState) =>
        prevState.set('editorState', action.payload.editorStateUpdate),
      );

    case CompositionsTypes.SET_COMPOSITION_LOCAL_STATE:
      return state.withMutations((prevState) =>
        prevState.set('compositionById', action.payload.composition),
      );

    case CompositionsTypes.CLEAR_PARENT_OR_CHILD:
      return state.withMutations((prevState) =>
        prevState.set('parentOrChildComposition', fromJS({})),
      );

    case CompositionsTypes.RESET_ERROR_AT:
      return state.withMutations((prevState) => prevState.set('errorAt', null));

    case CompositionsTypes.CLEAR_COMPOSITION:
      return state.withMutations((prevState) =>
        prevState.set('compositionById', fromJS({})),
      );

    case CompositionsTypes.RESET_SAVE_COMPOSITION_FAILURE_MESSAGE:
      return state.withMutations((prevState) =>
        prevState.set('saveCompositionFailureMessage', ''),
      );

    default:
      return state;
  }
};

export default reducer;
